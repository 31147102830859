import React from 'react';
import { FooterWrapper, FooterText } from './styles';

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
        <FooterText>I was built with TypeScript and React! View me in Chrome for best experience!</FooterText>
    </FooterWrapper>
  );
}

export default Footer;
