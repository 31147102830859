//LIGHT MODE
const sand = '#FCF9EA';
const tangerine = '#FF8A5C';
const teal = '#49BEB7';
const sky = '#BADFDB';
const cloud = 'rgb(0,0,0, 0.2)';

const sand_transparent = 'rgb(252,249,234, .6)';

export default {
    colors: {
        TRACK: sand,
        THUMB: tangerine,

        //NavBar colors
        NAV_BAR_HOVER: tangerine,
        NAV_BAR_HEADER: teal,
        NAV_BAR_ACTIVE: tangerine,
        NAV_BAR_SHADOW_SAFARI: sky,
        NAV_BAR_SHADOW: cloud,
        NAV_BAR_BACKGROUND: sand,

        //Contact page colors
        CONTACT_MEDIUM: sand,
        CONTACT_MEDIUM_HOVER: teal,
        CONTACT_HEADER: sand,
        LINKEDIN: sand,
        EMAIL: teal,
        EMAIL_BACKGROUND: sand,


        //Logo colors
        LOGO_BACKGROUND: sand_transparent,
        LOGO_TEXT: teal,
        LOGO_TEXT_HOVER: tangerine,
        LOGO_EMPHASIS: tangerine,
        LOGO_EMPHASIS_HOVER: teal,
        LOGO_BACKGROUND_HOVER: sand,
        LOGO_BACKGROUND_SAFARI: sand,

        //Page backgrounds
        TITLE_BACKGROUND: sky,
        SUBTITLE_BACKGROUND: teal,
        ACCENT_BACKGROUND: tangerine,
        PAGE_BACKGROUND: sand,
        CONTACT_BACKGROUND: teal,
        FOOTER_BACKGROUND: tangerine,

        //Text
        HEADER_TEXT: teal,
        TITLE_TEXT: sand,
        FOOTER_TEXT: sand,
        PARAGRAPH_TEXT: teal,

        //TOGGLE
        TOGGLE: teal,
        TOGGLE_HOVER: tangerine,

        //ABOUT
        RESUME_ITEM_TITLE: tangerine,
        RESUME_ITEM_SUBTITLE: teal,
        SIDEBAR: teal,
        PHOTO_SHADOW: teal,
        LINK: teal,
        LINK_HOVER: tangerine,

        //PROJECTS
        PROJECT_PREVIEW_OVERLAY: cloud,
        PROJECT_PREVIEW_OVERLAY_TEXT: sand,
        PROJECT_PREVIEW_TEXT_SHADOW: cloud,
        PROJECT_PREVIEW_BORDER: teal,
    }
}

